:import("@/assets/styles/components/label-error-provider.module.scss") {
  locl-label-error-provider__error: locl-label-error-provider__error;
}

:import("@/assets/styles/components/uploaded-image-box.module.scss") {
  locl-uploaded-image-box: locl-uploaded-image-box;
}

// mixins
@mixin col($count) {
  display: grid;
  grid-template-columns: repeat($count, 1fr);
  grid-gap: 16px;
}

// styles
.locl-add-edit-form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  &__col-2 { @include col(2); }
  &__col-3 { @include col(3); }
  &__col-4 { @include col(4); }
  &__col-5 { @include col(5); }
  &__col-6 { @include col(6); }
  &__col-7 { @include col(7); }


  &__set-location-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 134px;
    grid-gap: 16px;
  }

  &__input-wrapper-with-count {
    padding-bottom: 24px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.locl-add-edit-form-media {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
}

.locl-add-edit-form-floor-plan {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
  width: 100%;

  &__with-images {
    .locl-add-edit-form-floor-plan__upload {
      width: 158px;
      flex: 0 0 158px;
      height: 204px;
    }
  }

  &__upload {
    min-width: 158px;
  }
}

.locl-add-edit-form-floor-plan-card {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  padding: 8px 8px 32px;
  background: var(--white-color-500);
  border: 1px solid var(--secondary-color-300);
  box-shadow: 0px 1px 2px 0px rgba(31, 41, 55, 0.08);
  border-radius: 4px;

  width: 192px;
  max-width: 192px;
  flex: 0 0 191px;

  .locl-uploaded-image-box {
    width: 100%;
    height: 110px;
  }
  .locl-label-error-provider__error { display: none; }
}

.locl-add-edit-form-average-footfall {
  &__inputs {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 16px;
  }
}

.locl-add-edit-form-average-gender {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
}

.locl-add-edit-location-form-block-gray {
  padding: 16px;
  background: var(--secondary-color-200);
  border-radius: 4px;
}
