.locl-popular-searches {
  margin-bottom: 40px;
  line-height: 24px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;

  &__label {
    margin-bottom: 10px;
    color: var(--dark-color-500);
  }

  &__options {
    margin-top: 8px;
    display: grid;
    grid-template-columns: repeat(6, auto);
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
  }

  &__error {
    padding: 5px 0;
    line-height: 15px;
    min-height: 0;
    font-size: 12px;
    font-weight: 400;
    color: var(--danger-color-500);
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    min-height: 22px;
    color: #252525;
    font-weight: 400;
  }
}

.locl-popular-search-item {
  border-radius: 4px;
  border: 1px solid var(--secondary-color-400);
  background: var(--white-color-500);

  height: 80px;
  width: 80px;
  padding: 3px 7px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;

  transition: all .2s;

  &__label {
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: var(--secondary-color-500);
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__icon-image {
    width: 100%;
    height: 100%;
  }

  &_active {
    border-color: rgb(14, 133, 126);
    background: var(--primary-color-100);

    .locl-popular-search-item {
      &__label {
        color: rgb(14, 133, 126);
      }
    }
  }

  &:hover {
    box-shadow: 0px 1px 2px #a2a3a359;
  }
}

.locl-popular-searches-loading {
  .locl-popular-searches__options {
    :global {
      .ant-skeleton {
        .ant-skeleton-button {
          width: 70px;
          min-width: 70px;
          height: 70px;
          border-radius: 6px;
        }
      }
    }
  }
}
