.locl-features {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-auto-rows: max-content;
  grid-gap: 16px;
  width: calc(100% - 32px);

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;

    width: 100%;
  }

  &__item-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}
