.locl-landlord-reports-table {
  &__wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;
    
    padding: 15px 0;
  }

  &__link { color: var(--secondary-color-800); }
  &__hint {
    color: var(--dark-color-200);
  }

  &__space-name-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;

    font-size: 18px;
    font-weight: 600;
    color: var(--primary-color-500);
  }

  &__arrow-icon {
    display: block;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }

    &_good { color: var(--primary-color-500); }
    &_bad { color: var(--danger-color-500) }
  }
}
