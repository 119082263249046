:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old__label: locl-input-old__label;
  locl-input-old__error: locl-input-old__error;
  locl-input-old-wrapper: locl-input-old-wrapper;
}

:import("@/assets/styles/landlord/shared/checkbox.module.scss") {
  locl-checkbox: locl-checkbox;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
  locl-button__link: locl-button__link;
}


.locl-ll-member-form {
  margin-top: 30px;

  &__access-levels {
    &__label {
      margin-bottom: 10px;
      line-height: 24px;
      font-size: 16px;
      font-family: var(--secondary-font-family);
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    
    &__checkboxes {
      .locl-input-old-wrapper {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
  
  &__cancel {

    &.locl-button {
      background: transparent;
      box-shadow: none;
      color: #545454;
      text-decoration: underline;
      padding: 0 20px;
      font-family: var(--secondary-font-family);
      font-weight: 600;
  
      &:hover {
        background: transparent;
        color: #545454;
        text-decoration: underline !important;
      }
    }
  }

  &__submit {
    &.locl-button { 
      background: #FE6161;
      min-width: 90px;
      font-family: var(--secondary-font-family);
      font-weight: 600;
    }
  }

  .locl-input-old-wrapper {
    grid-gap: 10px;

    :global {
      .locl-input-old {
        min-height: 35px;
        border-color: #A4A4A4;
        border-radius: 0;
  
        &:disabled {
          color: #A4A4A4;
          background-color: #f5f5f5;
        }
  
        &:hover {
          border-color:#A4A4A4;
        }
      
        &:focus {
          border-color: #13B5AC;
          box-shadow: none;
        }
      }

      .locl-old-select {
        color: #252525;
  
        &__indicator {
          padding: 5px;
          color: #7D7D7D;
        }
  
        &__multi-value {
          background-color: rgb(19, 181, 172);
          color: #fff;
      
          &__label {
            color: #fff;
          }
        }
  
        &__control {
          box-sizing: border-box;
          font-size: 13px;
          min-height: 34px;
          border-radius: 0;
          color: #252525;
          border-color: #A4A4A4;
      
          &--is-selected {
            box-shadow: none;
            border-color: #A4A4A4;
          }
      
          &--is-focused {
            box-shadow: none;
            border-color: #A4A4A4;
          }
          &:hover {
            border-color: #A4A4A4;
          }
        }
      }  
    }

    .locl-input-old {
      &__label {
        font-size: 16px;
        line-height: 24px;
      }

      &__error {
        margin-top: -5px;
      }
    }

    .locl-checkbox {
      font-family: var(--secondary-font-family);

      &:hover {
        .locl-ll-member-form__checkbox-text {
          color: var(--primary-color-500);
        }
      }

      :global {
        .ant-checkbox {
          &-inner {
            width: 24px !important;
            height: 24px !important;
            flex: 0 0 24px !important;
            border-radius: 2px;
            border: 1px solid #a4a4a4;
  
            &:after {
              width: 8px !important;
              height: 14px !important;
            }
          }
  
          &.ant-checkbox-checked {
  
            .ant-checkbox-inner {
              background: #fff;
    
              &:after {
                border-color: var(--primary-color-500) !important;
              }
            }
          }
        }
  
        .ant-checkbox-disabled {
  
          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              border-color: #a4a4a4 !important;
  
              &:after {
                border-color: rgba(0, 0, 0, 0.25) !important;
              }
            }
          }
        }
      }
    }

    &__error {
      :global {
        .locl-old-select {
          &__control {
            border-color: #ff4d4f;
          }
        }
      }
    }
  }

  &__controls-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      margin-left: 10px;
    }
  }
}
