.locl-input-old-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 4px;

  &_full-width {
    width: 100%;

    :global {
      .react-tel-input input[type=tel] {
        width: 100%;
        border-radius: 0;
      }

      .react-tel-input :disabled {
        color: #a4a4a4;
        cursor: not-allowed;
      }
    }
  }


  :global(.ant-input-status-error) {
    border: 1px solid #ff4d4f !important;
  }
}

.locl-input-old {
  &__label {
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }

  &__error {
    line-height: 15px;
    min-height: 0;
    font-size: 12px;
    color: #D05050;
  }
}

.locl-text-area {
  position: relative;

  textarea {
    padding-bottom: 20px;
  }

  &_full-width { width: 100%; }

  &:global(.ant-input-affix-wrapper) {
    &:hover {
      border-color: #fe6161;
      box-shadow: 0 0 0 2px rgba(254, 97, 97, 0.2);
    }
  }

  &:global(.ant-input-affix-wrapper-status-error) {
    border-color: transparent !important;
  }
}

.locl-input-old-skeleton {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;

  &__label {
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin: 0;
  }

  &__field {
    &:global(.ant-skeleton-element) {
      width: 100%;
      
      :global {
        .ant-skeleton-input {
          border-radius: 4px;
          height: 36px;
          width: 100%;
        }
      }
    }
  }

  &__group {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__group-addon {
    padding: 0 11px;
    font-size: 14px;
  }
}
