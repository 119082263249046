.locl-landlord-reports-forecast {
  min-height: 400px;
  overflow: auto;
  padding: 15px;

  background: var(--white-color-500);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.22);

  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    font-size: 24px;
    line-height: 32px;
  }

  &__body {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    :global {
      .ant-picker {
        max-width: 200px;
      }

      .recharts-responsive-container {
        flex: 1 1 auto;
        min-height: 350px;
      }
    }
  }

  &__chart-legend {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 24px;
  }

  &__chart-legend-label {
    &:before {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #0C7570;
      margin-right: 8px;
      content: ' ';
    }
  }

  &__chart-tooltip {
    color: var(--secondary-color-800);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    background: var(--white-color-500);
    padding: 16px;
    border-radius: 15px;
    border: 1px solid var(--primary-color-500);

    &__text {


      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
