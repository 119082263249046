:import("@/assets/styles/components/campaign-planner-item.module.scss") {
  locl-campaign-planner-item-activity: locl-campaign-planner-item-activity;
}

:import("@/assets/styles/containers/campaign-planner/campaign-planner.module.scss") {
  locl-campaign-planner__events: locl-campaign-planner__events;
}

:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-create-schedule-modal {
  width: 660px !important;

  overflow: hidden;

  :global {
    .ant-modal-body {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      max-height: 70vh;
      min-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .locl-campaign-planner__events {
    padding: 0;
    margin-top: 0;
  }

  .locl-campaign-planner-item-activity {
    border: 1px solid var(--secondary-color-300) !important;
    background-color: transparent;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;

    padding: 70px 40px;
    background-color: var(--secondary-color-200);

    text-align: center;
    color: var(--gray-color-500);
  }

  &__empty-description {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin: 0;
    padding: 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    grid-gap: 24px;
  }

  &__schedule-name { padding-top: 12px; }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;

    border-radius: 4px;
    padding: 2px;
    margin-bottom: -8px;

    background-color: var(--gray-color-300);
  }

  &__buttons-divider {
    width: 1px;
    height: 24px;
    background-color: var(--gray-color-400);
    opacity: .4;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    background-color: var(--white-color-500);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 8px;

    padding: 20px 0 24px 0;

    border-top: 1px solid var(--gray-color-300);
  }
}

@media screen and (max-width: 720px) {
  .locl-create-schedule-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
    padding: 0;
    top: 0;

    .locl-modal__header-icon { align-self: flex-start; }

    .locl-campaign-planner__events { flex: 1 0 0; }

    :global {
      .ant-modal-content {
        height: var(--window-height, 100vh);
        display: flex;
        flex-direction: column;
        border-radius: 0 !important;
      }
  
      .ant-modal-header { padding: 16px 16px 24px; }
  
      .ant-modal-body {
        min-width: 100%;
        flex: 1;
        max-height: 100%;
        padding: 0 16px;
      }
    }

    &__schedule-name { padding-top: 0; }

    &__empty { flex: 1; }

    &__footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-top: 12px;

      &:before {
        content: " ";
        position: absolute;
        z-index: 2;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 20px;
        display: block;

        background: linear-gradient(to bottom, rgba(255, 255, 255,  0.3), rgba(255, 255, 255));
      }

      .locl-button { width: 100%; }
    }
  }
}
