:import("@/assets/styles/shared/button.module.scss") {
  locl-button_type-secondary: locl-button_type-secondary;
}

.locl-dropdown-inner-button {
  &__icon {
    flex: 0 0 14px;
    width: 14px;
    height: 14px;
  }

  &__value,
  &__label {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__value {
    font-weight: 500 !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[disabled] {
    .locl-dropdown-inner-button__label,
    .locl-dropdown-inner-button__value {
      color: var(--gray-color-400);
    }
  }

  &.locl-button_type-secondary {
    .locl-dropdown-inner-button {
      &__value,
      &__label {
        color: var(--secondary-color-500) !important;
      }

      &__icon {
        color: var(--gray-color-400) !important;
      }
    }
  }
}
