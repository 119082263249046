.locl-input-base-wrapper {
  position: relative;

  display: flex;
  align-items: center;

  &_width-addon-after,
  &_width-addon-before {
    .locl-input-number:global(.ant-input-number) {
      width: 100%;
    }
  }
}

.locl-input-number {
  &:global(.ant-input-number) {
    width: max-content;
    background: var(--white-color-500);
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;
    overflow: hidden;

    color: var(--secondary-color-500);
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    &:hover {
      border: 1px solid var(--gray-color-400);
      box-shadow: 0px 0px 0px 3px rgb(229, 231, 235);
    }

    & input,
    & textarea {
      font-family: var(--main-font-family);
    }

    :global {
      .ant-input-number-handler {
        border-left: none;
        height: 50% !important;
        border-radius: 4px;

        &:hover {
          color: var(--primary-color-500);
        }

        &:hover .ant-input-number-handler-up-inner,
        &:hover .ant-input-number-handler-down-inner,
        .ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
        .ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
          color: var(--primary-color-500);
        }
      }

      .ant-input-number-group-addon {
        background-color: transparent;
        border: none;
        padding: 0 8px;
        color: var(--gray-color-400);
      }

      .ant-input-number-handler-wrap {
        opacity: 1;
      }

      .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
      .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
        width: 10px;
        height: 10px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .ant-input-number-handler-up-inner {
        top: calc(50% + 2px);
      }
      .ant-input-number-handler-down {
        border-top: none !important;
        top: -2px;
      }

      .ant-input-number-handler-up-disabled,
      .ant-input-number-handler-down-disabled {
        cursor: not-allowed;

        &:hover .ant-input-number-handler-down-inner {
          color: rgba(0, 0, 0, 0.45) !important;
        }
      }
    }
  }

  &:global(.ant-input-number:has(.ant-input-number-input[disabled]):hover) {
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  }

  &:global(.ant-input-number:has(.ant-input-number-input:focus)) {
    border: 1px solid var(--primary-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
  }

  &:global(.ant-input-number:has(.ant-input-number-input:active)) {
    border: 1px solid var(--primary-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
  }

  &:global(.ant-input-number.ant-input-status-error) {
    border: 1px solid var(--rose-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(255, 228, 230) !important;
  }

  &:global(.ant-input-number.ant-input-number-group-wrapper) {
    :global {
      .ant-input-number {
        border: none;
        box-shadow: none;
      }

      .ant-input-number-input {
        padding-left: 0;
      }
    }
  }

  &:global(.ant-input-number__control-icon) {
    width: 20px;
    height: 10px;
  }

  &.locl-input-number_full-width {
    width: 100%;
  }
}

.locl-input {
  &__addon {
    position: absolute;
    z-index: 2;
    color: var(--gray-color-400);

    width: 20px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2px;

    svg {
      width: 100%;
      height: 100%;
    }

    &_before {
      left: 8px;
    }
    &_after {
      right: 8px;
    }
    &_pointer-none {
      pointer-events: none;
    }
  }

  &__addon-clear {
    cursor: pointer;
  }

  &:global(.ant-input),
  &:global(.ant-input-affix-wrapper) {
    background: var(--white-color-500);
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;

    height: 36px;
    width: 100%;
    padding: 8px;

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color-500);

    transition: all 0.2s;

    &:not([disabled]):hover {
      border: 1px solid var(--gray-color-400);
      box-shadow: 0px 0px 0px 3px rgb(229, 231, 235);
    }

    &:focus,
    &:active {
      border: 1px solid var(--primary-color-500) !important;
      box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
    }

    &:disabled {
      color: var(--gray-color-400);
      background: var(--secondary-color-200);
    }

    :global {
      .ant-input-suffix {
        position: absolute;
        top: calc(100% + 4px);
        right: 0;
      }
    }
  }

  &:global(.ant-input.ant-input-status-error) {
    border: 1px solid var(--rose-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(255, 228, 230) !important;
  }

  &:global(.ant-input.ant-input-sm) {
    padding: 4px 6px;
    height: 28px;
  }

  &.locl-input_width-addon-before {
    padding-left: 36px;
  }
  &.locl-input_width-addon-after {
    padding-right: 32px;
  }
}

.locl-input-password {
  &:global(.ant-input-affix-wrapper) {
    background: var(--white-color-500);
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    border-radius: 4px;

    height: 36px;
    width: 100%;
    padding: 8px;

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-color-500);

    transition: all 0.2s;

    :global {
      .ant-input-suffix {
        position: relative;
        top: auto;
        right: auto;
        cursor: pointer;

        width: 20px;
        height: 20px;
        flex: 0 0 20px;

        color: var(--gray-color-400);

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &:global(
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
          .ant-input-affix-wrapper-borderless
        ).ant-input-affix-wrapper
    ) {
    :global {
      .ant-input-suffix {
        color: var(--gray-color-400);
      }
    }
  }

  &:global(.ant-input-affix-wrapper:not([disabled]):hover) {
    border: 1px solid var(--gray-color-400);
    box-shadow: 0px 0px 0px 3px rgb(229, 231, 235);
  }

  &:global(.ant-input-affix-wrapper-focused) {
    border: 1px solid var(--primary-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
  }

  &:global(.ant-input-affix-wrapper-status-error) {
    border: 1px solid var(--rose-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(255, 228, 230) !important;
  }
}

.locl-search-input {
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

  :global {
    .ant-input {
      cursor: pointer;
    }
  }
}

.locl-text-input {
  &:global(.ant-input) {
    font-family: var(--main-font-family);
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      outline: none;
      border: 1px solid var(--gray-color-400);
      box-shadow: 0px 0px 2px 2px var(--secondary-color-300);
    }

    &:focus {
      border: 1px solid var(--primary-color-500);
      box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
    }
  }

  &:global(.ant-input.ant-input-status-error) {
    border: 1px solid var(--rose-color-500) !important;
    box-shadow: 0px 0px 0px 3px rgb(255, 228, 230) !important;
  }
}

.locl-tel-input {
  :global {
    .react-tel-input {
      background: var(--white-color-500);
      border: 1px solid var(--secondary-color-300);
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;

      transition: all 0.2s;

      &:hover {
        outline: none;
        border: 1px solid var(--gray-color-400);
        box-shadow: 0px 0px 2px 2px var(--secondary-color-300);
      }

      &:has(input:focus) {
        border: 1px solid var(--primary-color-500) !important;
        box-shadow: 0px 0px 0px 3px rgb(231, 248, 247) !important;
      }

      .form-control {
        width: 100%;
        height: 34px;
        padding: 8px;
        padding-left: 52px;

        border: none;

        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-color-500);

        &.locl-tel-input__error {
          border: 1px solid var(--rose-color-500) !important;
          box-shadow: 0px 0px 0px 3px rgb(255, 228, 230) !important;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .flag-dropdown {
        background-color: transparent;
        border: none;
      }

      .country-list .country.highlight {
        color: var(--primary-color-500);
      }

      .selected-flag .arrow {
        width: 20px;
        height: 20px;

        top: 0;
        margin-top: -5px;
        left: 20px;

        border-left: none;
        border-right: none;
        border-top: none;
        background-image: url("../../../assets/images/icons/HiChevronDown.svg");
        background-position: center;

        background-size: 100%;

        transition: all 0.2s;

        &.up {
          transform: rotate(180deg);
          border-bottom: none;
        }
      }
    }
  }
}

.locl-date-input {
  height: 40px;
  width: 126px;
  background: #f1f1f17f;
  border-radius: 8px;
  border: none;
  font-family: var(--secondary-font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  padding: 0 16px 0 16px;
  color: #252525;

  &.locl-date-input__active {
    border: 1px solid #13b5ac;
  }
}

.locl-input-skeleton {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;

  &__label {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color-500);
    margin-bottom: 0;
  }

  &__field {
    &:global(.ant-skeleton-element) {
      width: 100%;

      :global {
        .ant-skeleton-input {
          border-radius: 4px;
          height: 36px;
          width: 100%;
        }
      }
    }
  }
}
