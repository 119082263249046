.locl-old-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  grid-gap: 8px;

  &:global(.ant-pagination) {
    margin: 0 auto;
  }

  & > li { margin: 0 !important; }

  :global {
    .ant-pagination-prev, 
    .ant-pagination-next {
      width: 35px;
      height: 35px;
      flex: 0 0 35px;
      min-width: 35px;
  
      &:hover {
        .locl-old-pagination__arrow {
          background: rgba(0, 0, 0, 0.2);
          border-color: var(--dark-color-100);
          color: var(--black-color-500);
        }
      }
  
      .locl-old-pagination__arrow.ant-pagination-item-link {
        border-radius: 50%;
        background: var(--white-color-500);
        border: 1px solid var(--dark-color-100);
    
        color: var(--black-color-500);
    
        display: flex;
        align-items: center;
        justify-content: center;
    
        svg {
          width: 16px;
          height: 16px;
        }
    
    
        &:hover {
          background: rgba(0, 0, 0, 0.2);
          border-color: var(--dark-color-100);
        }
      }
    }
  
    .ant-pagination-disabled {
      .locl-old-pagination__arrow.ant-pagination-item-link {
        color: var(--gray-color-400);
  
        &:hover { background: var(--white-color-500); }
      }
    }
  
    .ant-pagination-item {
      border-radius: 100%;
      border: 1px solid var(--dark-color-100);
      background: var(--white-color-500);
  
      flex: 0 0 35px;
      width: 35px;
      height: 35px;
      min-width: 35px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      @media screen and (max-width: 620px) {
        flex: 0 0 32px;
        height: 32px !important;
        width: 32px;
      }
  
      .locl-old-pagination__page-item {
        padding: 0;
        font-family: var(--secondary-font-family);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--black-color-500);
      }
  
      .ant-pagination-item-link-icon { color: var(--primary-color-500); }
  
      &:hover {
        background: rgba(0, 0, 0, 0.2);
        border-color: var(--dark-color-100);
      }
  
      &.ant-pagination-item-active {
        background: var(--black-color-500);
        color: var(--white-color-500);
  
        &:hover {
          background: rgba(0, 0, 0, 1);

          .locl-old-pagination__page-item {
            color: var(--white-color-500);
          }
        }
      }
    }
  
    .ant-pagination-item-active {
      background: var(--black-color-500);
      
      .locl-old-pagination__page-item {
        color: var(--white-color-500);
      }
  
      &:hover {
        background: rgba(0, 0, 0, 1);
      }
    }
  
    .ant-pagination-item-link-icon { color: var(--primary-color-500) !important; }  
  }
}

@media screen and (max-width: 620px) {
  .locl-old-pagination {
    gap: 8px;

    &:global(.ant-pagination-simple) {
      :global {
        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
        }
      }
    }

    &:global(.ant-pagination-mini) {
      :global {
        .ant-pagination-prev,
        .ant-pagination-next {
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
        }
      }
    }

    :global {
      .ant-pagination-simple-pager {
        height: 32px;
    
        display: flex;
        align-items: center;
       }    
    }
  }
}
