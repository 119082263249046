.locl-checkbox-wrapper {
  &_size-small {
    min-width: 16px;
    min-height: 16px;
  }
}

.locl-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  grid-gap: 8px;

  :global {
    .ant-checkbox-wrapper {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      font-family: var(--main-font-family);
      color: var(--secondary-color-500);

      display: inline-flex;
      align-items: center;

      &:hover {
        :global {
          .ant-checkbox-inner {
            border-color: var(--primary-color-500);
          }
        }
      }

      .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
        .ant-checkbox-inner {
          background-color: var(--gray-color-300);
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: var(--primary-color-500) !important;
            background-color: var(--primary-color-400) !important;
          }
        }
      }
    }
  }
}

.locl-checkbox {
  &:global(.ant-checkbox-wrapper) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--main-font-family);
    color: var(--secondary-color-500);

    display: inline-flex;
    align-items: center;

    &:hover {
      :global {
        .ant-checkbox-inner {
          border-color: var(--primary-color-400) !important;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: var(--primary-color-400) !important;
        }
      }
    }
  }

  &:global(.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled) {
    :global {
      .ant-checkbox-inner {
        background-color: var(--gray-color-300) !important;
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: var(--primary-color-500) !important;
          background-color: var(--primary-color-400) !important;
        }
      }
    }
  }

  // TODO: check style priority
  &_size-small {
    :global {
      .ant-checkbox {
        flex: 0 0 16px !important;
        width: 16px !important;
        height: 16px !important;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-checkbox-inner::after {
        width: 8px !important;
        height: 8px !important;
      }

      .ant-checkbox,
      .ant-checkbox-input,
      .ant-checkbox-inner {
        flex: 0 0 16px !important;
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}

.locl-checkbox-group,
.locl-checkbox {
  :global {
    .ant-checkbox-inner {
      background: var(--white-color-500);
      border: 1px solid var(--secondary-color-400);
      box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
      border-radius: 4px;

      width: 20px;
      height: 20px;

      &::after {
        border-color: var(--white-color-500) !important;
      }
    }

    .ant-checkbox,
    .ant-checkbox-input,
    .ant-checkbox-inner {
      width: 20px !important;
      height: 20px !important;
      flex: 0 0 20px !important;
    }

    .ant-checkbox {
      top: 0;

      &:hover {
        .ant-checkbox-inner {
          border-color: var(--primary-color-500);
        }
      }
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: var(--primary-color-500);
      }

      .ant-checkbox-inner::after {
        width: 10px !important;
        height: 10px !important;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: var(--primary-color-500);
        background-color: var(--primary-color-500);
      }

      &::after {
        border-color: var(--primary-color-500);
        border-radius: 4px;
      }
    }
  }
}
