:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old-wrapper: locl-input-old-wrapper;
}

.locl-landlord-header {
  background: var(--landlord-header-background-color);
  color: var(--landlord-header-text-color);

  box-shadow: 0 1px 3px rgba(0,0,0,.22);
  justify-content: space-between;
  padding: 12px 24px;

  &__select {
    display: flex;

    flex-direction: row;
    align-items: center;
    gap: 12px;

    .locl-shared-select { width: 250px !important; }

    &.locl-input-old-wrapper {
      align-items: center !important;
      flex-direction: row !important;
      gap: 12px;
    }
  }

  .locl-base-header__profile { gap: 24px; }

  :global {
    .locl-landlord-header-select {
      width: 220px;
    
      &__menu {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
      }
    }
  }
}

.locl-landlord-header-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  grid-gap: 8px;

  cursor: pointer;

  &__user-name {
    padding: 0;
    margin: 0;

    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 14px;
    font-weight: 500;
  }

  &__avatar {
    position: relative;
  }

  &__avatar-notification {
    position: absolute;
    top: -1px;
    right: -1px;

    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
    background-color: rgb(208, 80, 80);
  }
}

.locl-landlord-header-messages {
  position: relative;
  display: block;
  min-width: unset;

  width: 24px;
  height: 24px;
  flex: 0 0 24px;

  @media screen and (max-width: 520px) {
    display: none;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border: none;
    background: transparent;
    transition: all 0.3s;

    color: var(--dark-color-400);

    &::after {
      content: unset;
      width: 10px;
      height: 10px;
      background-color: var(--danger-color-500);
      position: absolute;
      border-radius: 50%;
      top: -3px;
      right: -3px;
    }

    &_active:after { content: ""; }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
