:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

:import("@/assets/styles/containers/sortable-list.module.scss") {
  locl-sortable-list: locl-sortable-list;
}

.locl-locations-listing-location-card {
  background-color: var(--white-color-500);
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.22);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  &__title {
    font-size: 18px;
    line-height: 1.6;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    &__link {
      width: 24px;
      height: 24px;
      color: var(--dark-color-400);
      transition: all .2s;

      &:hover { color: var(--dark-color-500); }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__loader {
    &__title {
      &:global(.ant-skeleton) {
        width: 100%;
        max-width: 400px;

        :global {
          .ant-skeleton-title { height: 36px; }
        }
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__button {
      &:global(.ant-skeleton) {
        :global {
          .ant-skeleton-button {
            width: 24px;
            min-width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}

.locl-locations-listing-spaces {
  &__header {
    &__cell {
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.6;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;

    margin-top: 24px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 200px 1fr;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
  }

  &__card {
    width: 100%;
    background-color: var(--white-color-500);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.22);

    &__rate-card {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
    }

    &__price {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.85);

      gap: 8px;
      margin: 0;
    }

    &__price-label {
      white-space: nowrap;
    }
  }

  &__card-wrapper {
    width: 100%;

    &_dragging { opacity: 0; }
  }


  &__card-body {
    padding: 20px;
  }

  &__card-cell {
    padding: 0 10px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    font-size: 15px;
    font-weight: 400;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.85);

    &__link {
      color: var(--dark-color-400);
      font-weight: 700;

      max-width: 150px;
      word-break: break-word;
    }

    &__dnd-icon {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      display: block;

      width: 24px;
      flex: 0 0 24px;

      color: var(--dark-color-300);

      svg {
        width: 100%;
        height: 1000%;
      }
    }
  }

  &__card-footer {
    border-top: 1px solid var(--dark-color-200);
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    color: rgba(0, 0, 0, 0.85);

    &__view-space-link {
      font-weight: 600;
      color: var(--danger-color-500);
      display: flex;
      align-items: center;
      gap: 4px;
    }

    &__request-to-publish-btn {
      &.locl-button {
        background-color: var(--danger-color-500);
        color: var(--white-color-500);
        font-weight: 600;
        padding: 8px 16px;
        border-radius: 4px;
        transition: all .2s;

        &:focus { background-color: var(--danger-color-500); }
  
        &:global(.ant-btn:not(:disabled):not(.ant-btn-disabled):hover) {
          background-color: var(--danger-color-600); 
        }
      }      
    }

    &__request-to-un-publish-btn {
      &.locl-button {
        color: var(--secondary-color-500) !important;
        font-weight: 600;
  
        border: none;
        box-shadow: none !important;
        background-color: transparent !important;
        text-decoration: underline;
      }
    }

    &__restore-btn {
      &.locl-button {
        font-weight: 600;
        background-color: var(--dark-color-500) !important;
        border: none;
        box-shadow: none !important;
      }
    }
  
  }

  &__card-incomplete {
    color: #dd845a;
    font-weight: 600;
    margin-right: 16px;
  }

  &__card-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    &__item {
      width: 24px;
      height: 24px;
      flex: 0 0 24px;

      cursor: pointer;
      color: var(--dark-color-300);
      transition: all .2s;

      &:hover { color: var(--dark-color-500); }

      svg {
        width: 100%;
        height: 100%;
      }

      :global {
        .ant-badge {
          width: 100%;
          height: 100%;
          color: inherit;
        }

        .ant-badge-dot {
          width: 8px;
          height: 8px;
          flex: 0 0 8px;
          background: var(--danger-color-500);
        }
      }
    }
  }

  &__card-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;

    border-top: 1px solid var(--dark-color-200);
    padding: 10px 20px;

    color: rgba(0, 0, 0, 0.85);
    line-height: 1.6;

    &__label {
      white-space: nowrap;
      min-width: 75px;
    }

    &__value {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
    }
  }

  &__card-dynamic-prices {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;

    border-top: 1px solid var(--dark-color-200);
    padding: 10px 20px;

    &__column {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 18px;
    }

    &__label {
      white-space: nowrap;
      margin: 0;
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      row-gap: 4px;
    }

    &__item {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      gap: 4px;
    }
  }
}

.locl-locations-listing-spaces-card-loading {
  width: 100%;
  background-color: var(--white-color-500);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 200px 1fr;
    padding: 20px;
  }

  &__cell {
    padding: 0 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;

    &__button {
      &:global(.ant-skeleton) {
        :global {
          .ant-skeleton-button {
            width: 24px;
            min-width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    border-top: 1px solid var(--dark-color-200);
    padding: 10px 20px;
  }

  &__footer {
    border-top: 1px solid var(--dark-color-200);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    &__button {
      &:global(.ant-skeleton) {
        :global {
          .ant-skeleton-button {
            width: 100px;
            min-width: 100px;
            height: 32px;
          }
        }
      }
    }
  }
}
