.locl-billing-details-form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;

  &__section {
    margin-top: 8px;

    display: flex;
    flex-direction: column;
    grid-gap: 24px;

    .locl-billing-details-form__inputs-group {
      grid-gap: 24px;
    }
  }

  &__section-header {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
  }

  &__section-header-title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--secondary-color-500);
  }

  &__inputs-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px 24px;
  }

  &__radio-group {
    &:global(.ant-radio-group) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 12px;
    }
  }

  &__record-name {
    max-width: 280px;
  }
}
