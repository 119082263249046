:import("@/assets/styles/components/space-dropdown.module.scss") {
  locl-space-dropdown__inputs-group: locl-space-dropdown__inputs-group;
}
:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
}

.locl-search-filters {
  &__mobile-filters {
    display: none !important;

    @media screen and (max-width: 1200px) {
      display: flex !important;
    }
  }
  
  &__content {
    border: 1px solid var(--secondary-color-300);
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
    background-color: var(--white-color-500);
    border-radius: 0 8px 8px 8px;

    padding: 0 12px;
  
    display: flex;
    align-items: center;
    grid-gap: 16px;
  
    height: 64px;
    width: 100%;
  }

  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;

    flex: 1 1 auto;
  }

  &__container {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    width: 100%;

    @media screen and (max-width: 1100px){
      grid-gap: 4px;
    }

    :global {
      .locl-search-filters-field-wrapper { width: 100%; }
      
      .locl-search-filters-field {
        max-width: 240px;
        padding-left: 0;
      }

      .ant-dropdown-trigger {
        width: 100%;

        &:last-of-type {
          .locl-search-filters-field { margin-right: 0; }
      
          .locl-search-filters-field:after { display: none; }
        }

        &.ant-dropdown-open {
          background-color: var(--secondary-color-200);

          .locl-search-filters-field__label {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__where-select {
    max-height: unset;
    border-radius: 12px;
    overflow: hidden;
  }

  &_error {
    height: 86px;
  }
}

.locl-search-filters-field-wrapper {
  position: relative;
  min-width: 135px;
}

.locl-search-filters-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;

  position: relative;
  z-index: 2;

  max-width: 210px;
  padding: 8px 12px;
  margin-right: 10px;

  border-radius: 4px;
  border: 1px solid transparent;
  
  transition: all .2s;
  cursor: pointer;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    height: 100%;
    width: 1px;
    background-color: var(--secondary-color-300);
    z-index: 1;
  }

  &_with-value {
    padding: 8px 4px;
    transform: translate(0, 10px);

    .locl-search-filters-field__label {
      position: absolute;
      z-index: 3;

      transform: translate(-28px, -26px) scale(1);
    }

    &::after { top: -10px; }
  }

  &_with-error {
    border: 1px solid var(--rose-color-500);
    box-shadow: 0px 0px 0px 3px rgb(255, 228, 230);
  }

  &__error {
    position: absolute;
    top: calc(100% + 2px);

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--rose-color-500);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 4px;
    color: var(--gray-color-400);

    width: 100%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    flex: 0 0 20px;

    color: var(--gray-color-400);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__label,
  &__value {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
    margin-bottom: 0;

    cursor: pointer;

    &__additional {
      color: var(--secondary-color-500);
      line-height: 16px;
      white-space: nowrap;
    }
  }

  &__label {
    transition: all .2s;
    background-color: var(--white-color-500);

    &-optional {
      font-size: 12px;
      margin-left: 4px;
    }
  }

  &__value {
    color: var(--secondary-color-500);
  }

  &__arrow-indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    flex: 0 0 20px;
  }
}

@media screen and (max-width: 1300px) {
  .locl-search-filters {

    &__container {
      grid-gap: 4px;
    }

    &-field {
      margin-right: 4px;

      &::after {
        left: calc(100% + 4px);
      }

      &__label,
      &__value {
        max-width: 9.3vw;
      }
    }
  }
}

@media screen  and (max-width: 1200px) {
  .locl-search-filters {

    &__content {
      height: fit-content;
      flex-direction: column;
      padding: 12px;
      grid-gap: 12px;
    }

    &__size-overlay {
      border-radius: 12px;

      .locl-space-dropdown__inputs-group {
        margin-top: 16px;
        grid-template-columns: repeat(2, 140px);
        grid-gap: 24px;
        margin-bottom: 18px;
      }
    }

    &__btn-group {
      width: 100%;

      .locl-button { width: 100%; }
    }

    &__container {
      flex-direction: column;
      width: 100%;
      grid-gap: 12px;

      :global {
        .ant-dropdown-trigger {
          width: 100%;

          &:last-of-type {
            .locl-search-filters-field-wrapper {
              border-bottom: none;
              padding: 0;
            }
          }
        }
      }

      .locl-search-filters-field-wrapper {
        position: relative;
        width: 100%;
        border-bottom: 1px solid var(--secondary-color-300);
        padding: 0 0 11px;
      }

      .locl-search-filters-field {
        position: static;
        max-width: 100%;
        margin-right: 0;

        &:after { display: none; }

        &:global(.ant-dropdown-open) {
          background-color: transparent;
          border: 1px solid var(--primary-color-500);
          border-radius: 4px;
          box-shadow: 0 0 0 2px rgba(113, 211, 205, 0.3);
        }

        &__label {
          max-width: 135px;
        }

        &__value {
          max-width: 200px;
          font-weight: 500;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;

          &__additional {
            font-weight: 500;
          }
        }

        &__arrow-indicator {
          position: absolute;
          right: 12px;
        }

        &__inner {
          grid-gap: 8px;
          justify-content: flex-start;
        }

        &__error {
          top: 35px;
          font-size: 12px;
        }

        &_with-value {
          transform: initial;

          .locl-search-filters-field__label {
            position: static;
            transform: initial;
          }
        }
      }
    }
  }

  .locl-search-filters-field { padding: 7px 12px; }
}

@media screen and (max-width: 620px) {
  .locl-search-filters {
    .locl-search-filters__container {
      .locl-search-filters-field {
        
        &__label {
          max-width: 105px;
        }
        
        &__value {
          max-width: 125px;
        }
      }
    }
  }
}
