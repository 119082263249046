.locl-space-working-hours-field {
  column-count: 2;
  column-gap: 24px;
  list-style: none;

  padding: 0;

  &__day-item {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside:avoid;
    -moz-page-break-inside:avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;

    margin-bottom: 18px;
    position: relative;
  }

  &__label {
    display: inline-block;
    margin-bottom: 8px;

    line-height: 18px;
    font-size: 16px;
    font-family: var(--secondary-font-family);
    color: var(--dark-color-500);
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__error {
    line-height: 14px;
    min-height: 0;
    font-size: 12px;
    color: #D05050;
    position: absolute;

    top: 100%;
    bottom: 0;
  }

  &__time-picker {
    display: flex;
    grid-gap: 6px;
  }
}

.locl-space-working-hours-field-loading {
  width: 100%;

  .locl-space-working-hours-field__time-picker {
    width: 100%;
  }
}
