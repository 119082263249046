.locl-space-rule-input {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  grid-gap: 8px;

  &__abb-btn {
    font-family: var(--secondary-font-family);
    color: #ffffff;
    background: #13b5ac !important;
    border-color: #13b5ac !important;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    border-radius: 4px;
    padding: 4px 0;
    flex: 0 0 90px;
  }

  &__controls {
    flex: 0 0 90px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    grid-gap: 16px;
  }

  &__controls-icon {
    width: 32px;
    height: 32px;

    padding: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    background-color: #13b5ac;
    
    border-radius: 50%;
    overflow: hidden;
    opacity: .8;
    transition: all .2s;
    cursor: pointer;

    &:hover { opacity: 1; }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__controls-delete {
    background: rgb(254, 97, 97);
  }
}
