:import("@/assets/styles/shared/button.module.scss") {
  locl-button: locl-button;
  locl-button_type-tertiary: locl-button_type-tertiary;
}

:import("@/assets/styles/components/search-map.module.scss") {
  locl-search-map: locl-search-map;
}

:import("@/assets/styles/components/campaign-planner-list-empty.module.scss") {
  locl-campaign-planner-list-empty-wrapper: locl-campaign-planner-list-empty-wrapper;
}

:import("@/assets/styles/shared/input.module.scss") {
  locl-input: locl-input;
}

:import("@/assets/styles/shared/dropdown/dropdown-button.module.scss") {
  locl-dropdown-inner-button__value: locl-dropdown-inner-button__value;
  locl-dropdown-inner-button__label: locl-dropdown-inner-button__label;
  locl-dropdown-inner-button__icon: locl-dropdown-inner-button__icon;
}

.locl-campaign-planner-container {
  display: flex;
  grid-gap: 24px;

  &__body {
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.locl-campaign-planner {
  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;

    width: 100%;
  }

  &__search-wrapper {
    max-width: 240px;
    width: 100%;
  }

  &__search {
    &.locl-input {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25;
      height: 40px;
    }
  }
  
  &__filters-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 12px;

    .locl-button {
      height: 40px;
    }

    .locl-dropdown-inner-button {
      &__value,
      &__label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25;
      }

      &__label {
        color: var(--secondary-color-500) !important;
      }
    }
  }

  &__filters-mobile {
    .locl-label-error-provider__full-width { width: 100%; }
    .locl-dropdown-inner-button__value { max-width: 110px; }
  }

  &__content {
    padding: 24px 12px 0;

    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  }

  &__content-list {
    .locl-campaign-planner-list-empty-wrapper { padding: 12px; }
  }

  &__map-view {
    padding: 12px;

    width: 100%;
    max-height: 745px;
    overflow-y: auto;
    overflow-x: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;

    &__map-component {
      position: sticky;
      top: 0;

      height: 745px;
      max-width: 100%;
      min-width: 400px;
      border-radius: 4px;
      overflow: hidden;
    }

    &__map-component-loading {
      height: 100% !important;

      :global {
        .ant-skeleton-input { height: 100%; }
      }
    }

    .locl-search-map {
      width: 100%;
      height: 100%;
    }

    .locl-campaign-planner__events { padding: 0; } 

    .locl-campaign-planner-list-empty-wrapper {
      margin-top: 16px;
    }
  }

  &__events {
    padding: 12px;

    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    position: relative;

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: rgba(255, 255, 255, 0.2);
      pointer-events: all;

      :global {
        .ant-skeleton,
        .ant-skeleton-input {
          height: 100% !important;
          cursor: wait;
        }
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    grid-gap: 8px;
    padding: 4px 12px;
    border-radius: 4px;
    
    background-color: var(--gray-color-300);

    &__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      grid-gap: 8px;
      
      .locl-button {
        background-color: transparent !important;
      }
    }

    &__divider {
      height: 24px;
      border-left: 1px solid var(--gray-color-400);
      opacity: 0.4;
    }

    &__block-mobile {
      width: 100%;

      .locl-button {
        padding: 8px;
      
        &_type-tertiary:hover {
          color: var(--primary-color-500);
          background-color: transparent;
          border-color: transparent;
          box-shadow: none;
        }
      }

      &__top,
      &__bottom  {
        display: flex;
        align-items: center;
        grid-gap: 4px;
        flex-wrap: wrap;
      }

      &__top {
        padding-bottom: 4px;
        margin-bottom: 4px;

        border-bottom: 1px solid rgba(156, 163, 175, 0.4);

      }
    }
  }

  .locl-info-tooltip-icon {
    width: 24px;
    height: 24px;

    padding: 2px;
  }
}

.locl-campaign-planner-delete-modal-info {
  &__text { color: var(--black-color-500); }

  &__text-link {
    text-decoration: underline;
    color: var(--black-color-500);

    &:hover { color: var(--black-color-500) }
  }
}

@media screen and (max-width: 1200px) {
  .locl-campaign-planner {
    &__map-view {
      grid-template-columns: 1fr;
      max-height: none;

      &__map-component {
        order: -1;
        max-height: 420px;
        min-width: 100%;
      }
    }
  }
}


@media screen and (max-width: 720px) {
  .locl-campaign-planner {
    &__filters {
      flex-direction: column;
      grid-gap: 8px;
    }

    &__filters-block {
      width: 100%;

      .locl-button {
        width: 100%;
      }

      .locl-dropdown-inner-button {
        &__icon {
          margin-left: auto;
        }

        &__value {
          max-width: 100%;
          text-align: left;
        }
      }

      :global {
        .ant-dropdown-trigger {
          width: 100%;
        }
      }
    }

    &__search-wrapper { max-width: 100%; }

    &__content {
      padding: 12px 12px 0;
    }
  }
}
