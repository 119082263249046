.locl-file-uploader {
  cursor: pointer;
  opacity: 1;

  position: relative;
  margin-top: 16px;

  &_disabled {
    pointer-events: none;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    color: var(--primary-color-500);
  }

  &__row {
    display: flex;
    grid-gap: 10px;
  }

  &__input {
    position: relative;
    z-index: 2;
    cursor: pointer;
    opacity: 0.6;
    transition: .2s all;

    &:hover {
      opacity: 1;
    }
  }

  &__input-component {
    position: absolute;
    width: 100%;
    height: 100%;

    cursor: pointer;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;
      
    ::-webkit-file-upload-button { 
      cursor:pointer; 
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #E7F8F7;
    padding: 6px 10px;

    color: var(--dark-color-500);
  }

  &__files-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;
  }

  &__file-preview {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 24px;
    width: 100%;
    
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }

    &_error {
      grid-column: 1/3;
      margin-top: 4px;
      font-size: 12px;
      line-height: 15px;
      color: rgb(208, 255, 223);
    }
  }

  &__file-icon {
    width: 18px;
    height: 18px;
    position: relative;
    z-index: 1;

    color: var(--danger-color-500);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__file-name {
    max-width: 275px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    line-height: 12px;

    color: var(--dark-color-500);
  }

  &__close-icon {
    width: 24px;
    height: 24px;

    color: var(--dark-color-500);
  }

  &__url-uploader {
    &__wrapper {
      display: flex;
      max-width: 110px;
      padding: 6px 10px;
      background: var(--danger-color-300);
      align-items: center;
      opacity: 0.6;
      transition: opacity .2s;

      color: var(--dark-color-500);

      &:hover {
        opacity: 1;
      }
    }

    &__img {
      width: 16px;
      height: 16px;
      margin-right: 4px;

      color: var(--danger-color-500);
    }

    &__controls {
      opacity: 0.6;
      transition: .2s all;

      display: flex;
      align-items: center;
  
      &:hover {
        opacity: 1;
      }
    }

    &__input {
      margin-top: 8px;
      margin-bottom: 5px;
      height: 35px;
      font-size: 13px;
      width: 80%;
      margin-right: 8px;

      border: 1px solid var(--dark-color-500);
      padding: 5px;
    }

    &__icon {
      height: 22px;
      width: 22px;
      color: rgb(19,181,172);
    }
  }
}
