.locl-space-features-field {
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  list-style: none;

  padding: 0;
  margin: 0;
  margin-top: 8px;
  margin-bottom: -18px;
  
  &__inner {
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside:avoid;
    -moz-page-break-inside:avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;

    margin-bottom: 18px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    grid-gap: 8px;
  }
}

.locl-space-features-field-loading {
  &__checkbox {
    .ant-skeleton {
      .ant-skeleton-input {
        width: 100%;
        min-width: 100%;
        height: 20px;
      }
    }
  }

  &__description {
    .ant-skeleton {
      .ant-skeleton-input {
        width: 100%;
        min-width: 100%;
        height: 91px;
      }
    }
  }

  .locl-input-old-skeleton { width: 100%; }
}
