
:import("@/assets/styles/landlord/shared/checkbox.module.scss") {
  locl-checkbox: locl-checkbox;
}

:import("@/assets/styles/landlord/shared/input.module.scss") {
  locl-input-old-wrapper: locl-input-old-wrapper;
}

.locl-location-calendar {
  padding: 40px 64px 40px 20px;
  padding-top: 60px;
  background: var(--white-color-500);
  box-shadow: rgba(0, 0, 0, 0.22) 0px 1px 2px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  overflow: hidden;

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.7);
  }

  &__days-control {
    position: absolute;
    z-index: 2;
    margin: 0 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: rgba(0, 0, 0, 0.85);

    &_disabled { opacity: .4; }

    &_left {
      right: calc(100% + 4px);
    }

    &_right {
      left: calc(100% + 4px);
    }

    svg {
      margin: 5px;

      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  &__main-content { width: 100%; }
  &__options-column { margin-top: 37px; }

  &__options-row {
    position: relative;

    margin: 0;
    padding: 8px 0;
    height: 46px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(&:first-of-type) { border-top: 1px solid #CBCBCB; }
    &:last-of-type { border-bottom: 1px solid #CBCBCB; }

    &__label {
      font-size: 13px;
      line-height: 13px;
      font-weight: 600;
      color: var(--dark-color-500);
      width: 200px;

      display: flex;
      flex-direction: column;
      gap: 4px;
      
      cursor: pointer;
      position: relative;
      z-index: 1;

      &__text {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__update {
        color: #7D7D7D;
        font-size: 13px;
        font-weight: 400;
      }
    }

    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;

      width: 100vw;
      height: 100%;

      opacity: 0;
      transition: all .2s;
      background-color: var(--dark-color-100);
    }

    &:not(&_empty):hover::before { opacity: .3; }

    .locl-location-calendar-cell {
      width: max-content;
      padding: 0 8px;

      border-left: 1px solid var(--dark-color-100);
      border-right: none;
    }
  }

  &__days {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 12px;

    border-left: 2px solid var(--dark-color-500);
  }

  &__days-grid {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__row {
      position: relative;

      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: repeat(28, minmax(26px, 1fr));
    }
  }

  &__status-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 24px;

    margin-top: 32px;
  }

  &__preloader {
    padding: 40px 20px;
    background-color: var(--white-color-500);

    display: grid;
    grid-template-columns: 180px 1fr;
    grid-auto-rows: 340px;
    gap: 48px;

    :global {
      .ant-skeleton-element .ant-skeleton-input {
        width: 100%;
        height: 100%;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__calendar { height: 100%; }
    &__footer { height: 52px; }
  }

  &__edit-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    position: relative;
    z-index: 10;

    &__filter {
      display: flex;
      align-items: center;
      gap: 16px;

      margin-right: auto;
    }

    &__right {
      display: flex;
      gap: 16px;
      margin-left: auto;
    }

    &__select {
      &.locl-input-old-wrapper {
        gap: 8px;
        align-items: center;
        flex-direction: row;
      }
    }

    &__deselect {
      background-color: transparent;
      border: 1px solid var(--dark-color-400);
      color: var(--dark-color-400);

      &:focus,
      &:hover {
        background-color: transparent;
        border: 1px solid var(--dark-color-400);
        color: var(--dark-color-400);
      }
    }
  }

  &_edit-view {
    .locl-location-calendar__options-column { margin-top: 73px; }

    :global {
      .ant-checkbox-wrapper .ant-checkbox-inner {
        border-radius: 0;
        
        &:after {
          width: 10px !important;
          height: 10px !important;
        }
      }

      .ant-checkbox-wrapper .ant-checkbox,
      .ant-checkbox-wrapper .ant-checkbox-input,
      .ant-checkbox-wrapper .ant-checkbox-inner {
        width: 18px !important;
        height: 18px !important;
        flex: 0 0 18px !important;
      }
    }
  }

}

.locl-location-calendar-header-cell {
  text-align: center;
  color: var(--dark-color-300);
  font-weight: 400;
  min-height: 46px;
  border-right: 1px solid var(--dark-color-100);
  border-bottom: 1px solid var(--dark-color-100);

  position: relative;

  &_is-prev { opacity: .4; }
  &_today { color: var(--danger-color-500); }
  &_is-weekend {
    font-weight: 600;

    .locl-location-calendar-header-cell__title { color: var(--dark-color-500); }
  }

  &_is-sunday { border-right: 2px solid var(--dark-color-500); }

  &__title,
  &__hint {
    margin: 0;
    padding: 0 2px;

    background-color: var(--white-color-500);

    color: inherit;
    font-size: 14px;
    line-height: 1.6;
    font-weight: inherit;
  }

  &__title { margin: 0; }

  &__by-week {
    grid-column: span 7;

    color: var(--dark-color-500);
    font-family: var(--secondary-font-family);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    text-align: center;
    white-space: nowrap;

    padding: 12px 8px;

    border-right: 2px solid var(--dark-color-500);
    height: 100%;
  }
}

.locl-location-calendar-cell {
  position: relative;
  z-index: 2;
  border-right: 1px solid var(--dark-color-100);
  border-bottom: 1px solid var(--dark-color-100);

  height: 46px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  &_is-sunday { border-right: 2px solid var(--dark-color-500); }
  &_is-prev { opacity: .4; }

  &__checkbox {
    position: absolute;
    height: 20px;

    &_header {
      position: static;
      
      align-items: center;
      justify-content: center;

      margin: 8px 0;
    }
  }

  &__dynamic-price-arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__note {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;

    width: 12px;
    height: 12px;
    color: rgba(0, 0, 0, 0.85);

    &__icon {
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    position: relative;

    height: 100%;
    cursor: pointer;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .locl-location-calendar-cell__dynamic-price-arrow { color: rgba(0, 0, 0, 0.85); }

    &_under-option {
      background-color: var(--gold-color-500);
      outline: 1px solid var(--dark-color-100);

      .locl-location-calendar-cell__dynamic-price-arrow { color: var(--white-color-500); }
    }

    &_under-option-internal {
      background-image: none;
      background-color: var(--amber-color-500);
    }

    &_booking-external {
      background-color: rgba(129, 46, 81, 1);
      outline: 1px solid var(--dark-color-100);
      
      .locl-location-calendar-cell__dynamic-price-arrow { color: var(--white-color-500); }
    }

    &_unavailable {
      background-image: linear-gradient(-45deg, var(--dark-color-100) 25%, transparent 25%, transparent 50%, var(--dark-color-100) 50%, var(--dark-color-100) 75%, transparent 75%, transparent);
      background-size: 11px 11px;
      outline: 1px solid var(--dark-color-100);
    }

    &_unavailable-internal {
      background-image: none;
      background-color: var(--danger-color-800);
      
      .locl-location-calendar-cell__dynamic-price-arrow { color: var(--white-color-500); }
    }

    &_closed-scheduled {
      background-color: var(--dark-color-100);
      outline: 1px solid var(--dark-color-100);

      .locl-location-calendar-cell__dynamic-price-arrow { color: var(--white-color-500); }
    }
  }

  &__wl-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 24px;
    height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }
}

.locl-location-calendar-note-popover {
  :global {
    .ant-popover-inner {
      background-color: var(--dark-gold-color-500);
      padding: 0;
    }
    .ant-popover-arrow::before { background-color: var(--dark-gold-color-500); }
  
    .ant-popover-inner-content {
      padding: 10px 5px;
      border-radius: 5px;
    }
  }
}

.locl-location-calendar-closed-popover {
  :global {
    .ant-popover-inner { background-color: var(--dark-color-500); }
    .ant-popover-arrow::before  { background-color: var(--dark-color-500); }

    .ant-popover-inner-content {
      padding: 10px 5px;
      border-radius: 5px;
    }
  }
}

.locl-location-calendar-closed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  color: var(--white-color-500);
  cursor: pointer;
}

.locl-location-calendar-note {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;

  color: var(--white-color-500);

  &__input {
    border: none;
    outline: none;
    background-color: transparent;

    &:disabled { background-color: transparent; }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &__icon {
      height: 18px;
      width: 18px;
      flex: 0 0 18px;

      cursor: pointer;

      &_editing {
        height: 24px;
        width: 24px;
        flex: 0 0 24px;
      }
    }
  }
}

.locl-location-calendar-inner {
  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-left: 50px;

    &__item {
      color: var(--dark-color-400);
      cursor: pointer;

      width: 20px;
      height: 20px;
      flex: 0 0 20px;

      display: block;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__event-info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__event-info__row {
    margin: 0;
    font-size: 12px;
  }

  &__event-info__body {
    margin-top: auto;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 52px;
  }

  &__event-info__price {
    text-align: right;
    font-weight: 600;
    font-size: 16px;
  }
}
