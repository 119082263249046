.locl-landlord-location-members {
  width: 770px;
  margin: 30px auto 80px;

  // need to rewrite from Inter to Montserrat
  * {
    font-family: var(--secondary-font-family);
  }

  &__title {
    margin: 10px 0;
    font-size: 16px;
    line-height: 48px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }

  &__content {
    background: #fff;
    padding: 40px 50px;
    margin-bottom: 3rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  }
}
