.locl-space-rules-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 44px;

  .locl-input-old-wrapper { gap: 16px; }

  &__days-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 4px;
  }

  &__days-input-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 8px;
  }

  &__radio-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 18px;
  }

  &__radio {
    flex-direction: row;
    align-items: center;
    grid-gap: 8px;

    .locl-radio-button {
      margin-right: 0;
    }
  }

  &__rules-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    grid-gap: 18px;
  }

  &__radio-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 4px;
  }

  &__radio-label {
    line-height: 20px;
    font-size: 16px;
    font-family: var(--secondary-font-family);
    font-weight: 500;
  }
}

.locl-space-rules-form-loading {
  &__terms {
    :global {
      .ant-skeleton {
        .ant-skeleton-input {
          height: 120px;
        }
      }
    }
  }
}
