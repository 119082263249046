.locl-dropdown {
  display: inline-flex;

  &__inner {
    width: max-content;
    display: inline-flex;

    &_disabled {
      opacity: .5;

      * {
        cursor: not-allowed;
      }
    }
  }

  &__inner-button {
    width: max-content;
    display: inline-flex;
  }
}

.locl-dropdown-options {
  max-height: 250px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);

  &__checkbox-item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
  }

  :global {
    .ant-dropdown-menu {
      padding: 8px;
      box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);
      border-radius: 4px;
      background-color: var(--white-color-500);

      .ant-dropdown-menu-item {
        padding: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-family: var(--main-font-family);
        color: var(--secondary-color-500);

        &.ant-dropdown-menu-item-selected,
        &.ant-dropdown-menu-item-active {
          color: var(--secondary-color-500);
          background-color: var(--gray-color-300);
        }
      }
    }
  }
}

.locl-when-dropdown {
  max-height: unset;

  @media screen and (max-width: 1100px) {
    width: max-content;
    max-width: 90vw;
  }
}
