:import("@/assets/styles/containers/single-campaign-header.module.scss") {
  locl-single-campaign-header-mobile: locl-single-campaign-header-mobile;
}

:import("@/assets/styles/containers/campaign-planner/campaign-planner.module.scss") {
  locl-campaign-planner-container: locl-campaign-planner-container;
}

.locl-single-campaign-page {
  &__button-back {
    padding: 0 8px;
    height: auto;
  }
}

@media screen and (max-width: 867px) {
  .locl-single-campaign-page {
    &.locl-single-campaign-page { padding: 48px 16px; }

    :global {
      .locl-single-page-layout__content { padding: 0; }
    }
  }
}

@media screen and (max-width: 720px) {
  .locl-single-campaign-page {
    &.locl-single-campaign-page { padding: 24px 16px 40px; }

    .locl-single-campaign-header-mobile { margin: 0 -16px; }
    .locl-campaign-planner-container { margin: 0 -16px; }
  }
}
