.remove-animation {
  .locl-toggle-add-button{
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: 0s !important;
    span, svg, p{
      animation-duration: 0s !important;
      -webkit-animation-duration: 0s !important;
      transition: 0s !important;
    }
  }
}

.locl-toggle-add-button {
  &_wrapper{
    position: relative;
    width: 80px;
    top: -15px;
  }
  height: 30px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  width: fit-content;
  padding: 9px;
  cursor: pointer;
  transition:  all 0.2s;
  flex-wrap: nowrap;
  overflow: hidden;
  background: white;

  &:hover{
    background: #f2efef;
  }

  p{
    margin-top: 0;
    transition: 0.2s;
    margin-right: 0px;
    font-size: 14px;
    margin-bottom: 0;
    color: rgba(55, 65, 81, 1);
    white-space: nowrap;

      span{
        width: 0;
        animation: fade-out 0.2s forwards ease-in;
        display: inline-block;
        opacity: 0;
      }
  }

  svg{
    display: inline-block;
    margin-right: 8px;
    color: rgba(55, 65, 81, 1);
    height: 15px;
    animation: fade-in-svg 0.4s forwards ease;
  }
}

.toggled{
  &:hover{
    background: rgba(244, 63, 94, 0.1)
  }
  p{
    color: rgba(244, 63, 94, 1);
    transition: all 0.2s ease;
    margin-right: 15px;
    span{
      animation: fade-in 0.4s forwards ease;
      display: inline-block;
      opacity: 1;
    }
  }
  svg{
    rect{
      animation: fade-out-svg 0.2s forwards ease;
        fill: rgba(244, 63, 94, 1)
      }
  }
}

@keyframes fade-out{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
@keyframes fade-in{
  0%{
    height: 0;
    opacity: 0.5;
  }
  50%{
    width: 11px;
  }
  100%{
    opacity: 1;
    width: 4px;
  }
}
@keyframes fade-in-svg{
  0%{
    height: 3px;
    opacity: 0.5;
    filter: blur(10px)
  }
  100%{
    height: 15px;
    opacity: 1;
    filter: blur(0)
  }
}
@keyframes fade-out-svg{
  0%{
    opacity: 0.5;
    filter: blur(4px)
  }
  100%{
    height: 8px;
    opacity: 1;
    filter: blur(0)
  }
}
