.locl-location-calendar-price-card {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 2px;

    border-bottom: 1px solid var(--dark-color-100);
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  &__header-title {
    font-size: 14px;
    font-weight: 600;
    
    margin: 0;
  }

  &__header-sub-title {
    font-size: 13px;
    color: var(--dark-color-200);

    margin: 0;
  }

  &__body-row {
    display: grid;
    grid-column-gap: 4px;
    grid-row-gap: 8px;
    grid-template-columns: 150px 100px 1fr auto;
    
    width: 390px;
    transition: all .2s;

    &:hover {
      background-color: var(--dark-color-100);
    }
  }

  &__body-row-value {
    font-size: 13px;
    font-weight: 600;

    margin: 0;

    &_green { color: var(--primary-color-500) }
    &_red { color: var(--danger-color-500) }
    &_crossed {
      text-decoration: line-through;
      color: var(--dark-color-200);
    }
  }
}
