.locl-checkbox-group,
.locl-checkbox {
  align-items: center;

  &:global(.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover) {
    :global {
      .ant-checkbox .ant-checkbox-inner {
        border-color: #13b5ac;
      }
    }
  }

  :global {
    .ant-checkbox {
      height: 24px;
      width: 24px;
  
      flex: 0 0 24px;
      top: 0;
    }
  
    .ant-checkbox-inner {
      width: 100%;
      height: 100%;
  
      &::after {
        height: 12px;
        width: 12px;
      }
  
      &:hover {
        border-color: #13b5ac;
      }
    }
  
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #13b5ac;
        border-color: #13b5ac;
      }
      &:not(.ant-checkbox-disabled):hover {
        .ant-checkbox-inner {
          border-color: #13b5ac !important;
          background-color: #13b5ac !important;
        }
      }
    }
  
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.locl-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  grid-gap: 8px;
}
