.locl-floor-plan-positioning {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__select {
    width: 320px;
  }

  &__source-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: max-content;
    z-index: 100;
  }

  &__source {
    border: 1px dashed #CA8A04;
    border-radius: 4px;
    padding: 8px;

    display: flex;
    align-items: center;
    gap: 8px;

    &__icon {
      width: 40px;
      height: 40px;
      color: var(--danger-color-500)
    }

    &__text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__hint {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #CA8A04;
  }

  &__image-container {
    width: max-content;
    height: max-content;

    position: relative;

    &__pin {
      cursor: pointer;
      background-color: transparent;
      position: absolute;

      & > * {
        background-color: transparent;
      }
    }
  }
}
