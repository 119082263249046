.locl-space-rate-card-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 44px;

  &__header-title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: var(--dark-color-500);

    &__icon {
      margin-left: 4px;
      font-size: 24px;
      color: #baa38b;
    }

    &__tooltip {
      :global {
        .ant-tooltip-inner {
          background: #baa38b;
        }
  
        .ant-tooltip-arrow {
          &::before {
            background: #baa38b;
          }
        }
      }
    }
  }

  &__header-description {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: var(--dark-color-500);
  }


  &__input {
    :global {
      .ant-input-number { width: 100%; }
    }
  }

  &__inputs-group {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: var(--dark-color-500);

      margin: 0;
      padding: 0;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      color: var(--dark-color-500);

      margin: 0;
      padding: 0;
    }

    &__body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      &_column { grid-template-columns: 1fr; }

      .locl-radio-group {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }
    }
  }

  &__day-discounts {
    width: 100%;
    background-color: var(--white-color-500);

    &__header {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
      color: var(--dark-color-500);

      padding: 24px 16px;
    }

    &__row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 84px;
      align-items: center;
      grid-auto-rows: minmax(47px, 62px);
      border-bottom: 1px solid #DEE2E6;

      :global {
        .ant-input-number {
          border: none;
          width: calc(100% - 5px);
        }
      }
    }

    &__row-control {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    &__header-cell {
      margin: 16px;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      color: var(--dark-color-500);

      &:not(:last-child) { border-right: 1px solid rgba(0, 0, 0, 0.03); }
    }

    &__add-new-price-btn {
      background-color: transparent !important;
      color: var(--danger-color-500) !important;
      border-radius: 4px;
      border: 1px solid var(--danger-color-500);

      margin: 4px 16px 24px auto;

      &:focus {
        background-color: transparent !important;
        color: var(--danger-color-500) !important;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__weekly-pricing {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;

    &__radio-button {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 8px;

      :global {
        .ant-radio-wrapper {
          width: 100% !important;
        }
      }
    }

  }
}
